import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { GlossaryDTO } from '../../../../../src/app/core/_models';
import { AlertType } from '../../../../../src/app/core/_models/system';
import { CoursesService, UserService } from '../../../../../src/app/core/services';
import { AlertMessageComponent } from '../alert-message/alert-message.component';
import { TrainersDialogComponent } from '../trainers-dialog/trainers-dialog.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'sm-glossary-certificate',
  templateUrl: './glossary-certificate.component.html',
  styleUrls: ['./glossary-certificate.component.css']
})
export class GlossaryCertificateComponent implements OnInit {
  glossary: GlossaryDTO
  loading = false;
  currentCourse: any;
  submitted = false;
  profile: any;

  newCourse = new FormGroup({
    // Define 'parentx' form control with validation
    name: new FormControl( this.data?.name),
    description: new FormControl(this.data?.description),
    id: new FormControl(this.data?.id),
    courseId: new FormControl(this.data?.courseId),
    isPublic: new FormControl(this.data?.isPublic),
    displayDescription: new FormControl(this.data?.displayDescription),
    approved: new FormControl(this.data?.approved),
    allowEditing: new FormControl(this.data?.allowEditing),
    duplicates: new FormControl( this.data?.duplicates),
    comments: new FormControl(this.data?.comments),
    linking: new FormControl(this.data?.linking),
  });

  constructor( private _translate: TranslateService,private _course: CoursesService,private _store: Store<any>, private cd: ChangeDetectorRef, private _user: UserService, public dialogRef: MatDialogRef<GlossaryCertificateComponent> ,  private _dialog: MatDialog , @Inject(MAT_DIALOG_DATA) public data: any){

    if (typeof window !== 'undefined') {

      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
  }
  ngOnInit(): void {
    this._store.dispatch({ type: '[SYSTEM] Get My Permissions', userId: this.profile.userId });
    this._store.dispatch({ type: '[SYSTEM] We Are On The Load' });
    var url = window.location.href;
    var xurl = url.split('/');
    this.currentCourse = + xurl[5];  
    this.newCourse.patchValue({
      courseId: this.currentCourse | this.currentCourse

    })
  
    // console.log('dataaaaa' , this.data);
    // console.log('dataaaaa' ,this.newCourse.value);
    
  
  }
  get xf() { return this.newCourse.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.newCourse.invalid) {
      return;
    }
    this.loading = true;

    this._store.dispatch({ type: '[SYSTEM] We Are On The Load' });
    this._course.addOrModifyGlossary(this.newCourse.value).subscribe((data: GlossaryDTO) => {
      this._course.getGlossaryByCourseId(this.currentCourse).subscribe((data: GlossaryDTO) => {
        this._store.dispatch({ type: '[SYSTEM] We Are Out Of Loading' });
        if (data) {
          this.glossary = data;

          this.cd.markForCheck();

        }
      })
      this.cd.markForCheck();
      var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.GLOSSARYADDEDSUCCESSFULLY'), type: AlertType.Success }
      const dialog = this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
      dialog.afterClosed().subscribe((data) => {        
        this.dialogRef.close();
        this.newCourse.reset();
        this.cd.markForCheck();          
      
    })
      this.submitted = false;
        
      this.glossary = data;


    }, (e) => {
      this._store.dispatch({ type: '[Profile] Stop Loading' });
      var alertData = { title: this._translate.instant('DIALOGS.ERROR'), body: this._translate.instant('DIALOGS.ERRORHAPPENED') + ' ' + e.error, type: AlertType.Error }
      this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
    })
  }

  close(){
    this.dialogRef.close()
  }
}
